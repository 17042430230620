import { Controller, useForm } from 'react-hook-form'
import { ServiceEditFormProps } from './types'
import { ServiceModel, ServiceCreateModel } from 'shared/models'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'

function ServiceEditForm({
  defaultData,
  onSubmit,
  isProcess,
  addressList,
  isEdit,
  onDelete,
  serviceList
}: ServiceEditFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const { control, handleSubmit, watch, setValue } = useForm<ServiceModel | ServiceCreateModel>({
    defaultValues: defaultData,
    disabled: isProcess,
    resolver: yupResolver<ServiceModel | ServiceCreateModel>(getValidations())
  })

  function handleDelete() {
    setIsDeleting(true)
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Название"
            fullWidth
            autoComplete="off"
            placeholder="Название"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={isProcess}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
      <Controller
        control={control}
        name="addressId"
        render={({ field, fieldState }) => (
          <FormControl fullWidth variant="standard" margin="dense">
            <InputLabel>Адрес</InputLabel>
            <Select
              value={field.value || ''}
              onChange={field.onChange}
              error={!!fieldState.error}
              fullWidth
              disabled={isProcess}
            >
              {addressList?.map?.((address) => (
                <MenuItem key={address.id} value={address.id}>
                  {[address.city, address.address].filter(Boolean).join(', ')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="parentServiceId"
        render={({ field, fieldState }) => (
          <Autocomplete
            value={serviceList?.find?.((s) => s.id === field.value) || null}
            fullWidth
            onChange={(_, newValue) => {
              field.onChange(newValue?.id || null)
            }}
            options={serviceList.filter((s) => !!s.withReservation && s.id !== defaultData?.id)}
            disabled={isProcess}
            getOptionLabel={(option) => option.name}
            clearIcon={<ClearIcon />}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Родительская услуга для расчета тамслотов"
                margin="dense"
                variant="standard"
                size="small"
                InputLabelProps={{ shrink: true }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name="isGiftCertificate"
        defaultValue={false}
        render={({ field }) => (
          <FormControlLabel
            style={{ margin: '1.5rem 0 1rem', display: 'flex', justifyContent: 'space-between' }}
            control={
              <Switch
                checked={field.value}
                onChange={(_, value) => {
                  field.onChange(value)
                  setValue('withReservation', false)
                  setValue('disableTimeSlotsForSiteReservation', false)
                }}
                onBlur={field.onBlur}
                disabled={isProcess || (watch('withReservation') && isEdit)}
                size="small"
              />
            }
            label="Подарочный сертификат"
            labelPlacement="start"
          />
        )}
      />
      {!watch('isGiftCertificate') && (
        <Controller
          control={control}
          name="withReservation"
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              style={{ margin: '1.5rem 0 1rem', display: 'flex', justifyContent: 'space-between' }}
              control={
                <Switch
                  checked={field.value}
                  onChange={(_, value) => {
                    field.onChange(value)
                    setValue('disableTimeSlotsForSiteReservation', false)
                  }}
                  onBlur={field.onBlur}
                  disabled={isProcess || isEdit}
                  size="small"
                />
              }
              label="Услуга с возможностью бронирования"
              labelPlacement="start"
            />
          )}
        />
      )}
      {!!watch('withReservation') && (
        <Controller
          control={control}
          name="disableTimeSlotsForSiteReservation"
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              style={{ margin: '1.5rem 0 1rem', display: 'flex', justifyContent: 'space-between' }}
              control={
                <Switch
                  checked={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  size="small"
                />
              }
              label="Деактивировать таймслоты для записи с сайта"
              labelPlacement="start"
            />
          )}
        />
      )}
      {!isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: '1rem' }}>
          {isEdit && (
            <Button onClick={handleDelete} disabled={isProcess}>
              Удалить
            </Button>
          )}
          <Button type="submit" variant="outlined" disabled={isProcess}>
            Сохранить
          </Button>
        </Box>
      )}
      {isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3, gap: '1rem' }}>
          Вы действительно хотите удалить услугу?
          <Button onClick={cancelDelete} disabled={isProcess}>
            Отмена
          </Button>
          <Button variant="outlined" color="error" disabled={isProcess} onClick={onDelete}>
            Удалить
          </Button>
        </Box>
      )}
    </form>
  )
}

export { ServiceEditForm }
