import { useMutation, useQuery, useQueryClient } from 'react-query'
import { adminService } from 'shared/api'
import { BookingScheduleListProps } from './types'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import TimeIcon from '@mui/icons-material/AccessTime'
import DateIcon from '@mui/icons-material/Event'
import AddIcon from '@mui/icons-material/Add'
import ExportIcon from '@mui/icons-material/CloudDownload'
import ImportIcon from '@mui/icons-material/CloudUpload'
import { Fragment, useState } from 'react'
import { ScheduleEdit } from './service-booking-edit'
import { ServiceScheduleCreateModel, ServiceScheduleExportModel, ServiceScheduleModel } from 'shared/models'
import { nativeFilesSelect } from 'shared/utils/file'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'

function ScheduleList({ serviceId, disabled }: BookingScheduleListProps) {
  const queryClient = useQueryClient()

  const dispatch = useAppDispatch()

  const { data, isLoading } = useQuery(
    ['scheduleList', serviceId],
    ({ signal }) => adminService.service.getServiceScheduleList(Number(serviceId), { signal }),
    { enabled: !!serviceId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createSchedule, isLoading: isCreateLoading } = useMutation((data: ServiceScheduleCreateModel) =>
    adminService.service.createServiceSchedule(data)
  )
  const { mutateAsync: deleteSchedule, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.service.deleteServiceSchedule(id)
  )

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editScheduleId, setEditScheduleId] = useState<null | number>(null)

  function handleScheduleEditClose() {
    setEditModalIsOpen(false)
    queryClient.invalidateQueries(['scheduleList', serviceId])
  }

  function handleExport() {
    if (!data) {
      return
    }

    const scheduleList: ServiceScheduleModel[] = data.data || []
    const scheduleListForExport: ServiceScheduleExportModel[] = scheduleList.map((price) => ({
      duration: price.duration,
      name: price.name,
      reservationIsAvailable: price.reservationIsAvailable,
      schedule: price.schedule
    }))

    const json = JSON.stringify(scheduleListForExport)

    const blob = new Blob([json], { type: 'application/json' })
    const href = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = href
    link.download = `scheduleList_${serviceId}_${new Date().toISOString()}.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  async function handleImport() {
    try {
      const fileRaw = await nativeFilesSelect({ multiple: false, accept: '.json' })
      const resText = await fileRaw.text()
      const prices = JSON.parse(resText) as ServiceScheduleExportModel[]

      if (!!prices?.length && !!prices.filter((p) => !!p.duration).length) {
        const scheduleForCreate: ServiceScheduleCreateModel[] = prices.map((p) => ({
          name: p.name || '',
          duration: p.duration,
          reservationIsAvailable: p.reservationIsAvailable ?? true,
          schedule: p.schedule,
          serviceId: serviceId
        }))

        const currentSchedule: ServiceScheduleModel[] = data?.data || []

        for (const p of currentSchedule) {
          await deleteSchedule(p.id)
        }

        for (const p of scheduleForCreate) {
          await createSchedule(p)
        }

        queryClient.invalidateQueries(['scheduleList', serviceId])
      } else {
        dispatch(showMessage({ text: 'Нет доступных расписаний для импорта', type: MessageTypes.Error }))
      }
    } catch (e) {
      dispatch(showMessage({ text: 'Не удалось импортировать файл', type: MessageTypes.Error }))
    }
  }

  return (
    <>
      {(isCreateLoading || isDeleteLoading || !!isLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !isCreateLoading && !isDeleteLoading && (
        <>
          <Box
            padding={1}
            sx={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Button startIcon={<ImportIcon />} onClick={handleImport} disabled={disabled}>
              Импортировать
            </Button>
            <Button startIcon={<ExportIcon />} onClick={handleExport} disabled={disabled}>
              Экспортировать
            </Button>
          </Box>
          <List disablePadding>
            {data?.data?.map?.((schedule) => (
              <Fragment key={schedule.id}>
                <div style={{ marginBottom: '1rem' }}>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ fontWeight: 500, color: disabled ? 'GrayText' : undefined }}
                      primary={schedule.name}
                      secondaryTypographyProps={{ variant: 'body2', variantMapping: { body2: 'div' } }}
                      secondary={
                        <>
                          <Typography
                            variant="body2"
                            color={disabled ? 'GrayText' : schedule.reservationIsAvailable ? '#4caf50' : '#f44336'}
                          >
                            {schedule.reservationIsAvailable ? ' Бронирование доступно' : ' Бронирование недоступно'}
                          </Typography>
                          {!!schedule.duration && (
                            <Typography variant="body2">Длительность: {schedule.duration} мин.</Typography>
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          setEditScheduleId(schedule.id)
                          setEditModalIsOpen(true)
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '0 1rem' }}>
                    {schedule.schedule?.map((schedule, i) => (
                      <div
                        key={`schedule-${i}`}
                        style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', padding: '0.25rem 0' }}
                      >
                        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                          {schedule.date ? (
                            <Box sx={{ display: 'flex', gap: '0.5rem', opacity: disabled ? 0.5 : 1 }}>
                              <DateIcon fontSize="small" />
                              <Typography variant="body2">
                                {Intl.DateTimeFormat('ru', { timeZone: 'UTC' }).format(schedule.date)}
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', gap: '0.25rem', marginBottom: '0.25rem' }}>
                              {schedule.weekdays
                                ?.map((w) => ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][w])
                                .map((w, wi) => (
                                  <Chip
                                    disabled={disabled}
                                    size="small"
                                    color={disabled ? 'default' : 'primary'}
                                    label={w}
                                    key={`schedule-${i}-weekday-${wi}`}
                                  />
                                ))}
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: '0.5rem', opacity: disabled ? 0.5 : 1 }}>
                          <TimeIcon fontSize="small" />
                          <Typography variant="body2">
                            {[
                              Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                schedule.startTime
                              ),
                              Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(schedule.endTime)
                            ].join(' - ')}
                          </Typography>
                        </Box>
                      </div>
                    ))}
                  </div>
                </div>
                <Divider />
              </Fragment>
            ))}
            <Box style={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 1rem' }}>
              <IconButton
                onClick={() => {
                  setEditScheduleId(null)
                  setEditModalIsOpen(true)
                }}
                disabled={disabled}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </List>
        </>
      )}
      <Dialog open={editModalIsOpen} onClose={() => setEditModalIsOpen(false)} fullWidth>
        <ScheduleEdit
          key={editScheduleId || undefined}
          onClose={handleScheduleEditClose}
          scheduleId={editScheduleId || undefined}
          serviceId={serviceId}
        />
      </Dialog>
    </>
  )
}

export { ScheduleList }
